var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.imageParam[0],"auto-zoom":false,"stencil-props":{
      handlers: {},
      movable: false,
      scalable: false,
      resizable: false,
      minAspectRatio: _vm.minAspectRatioValue1 / _vm.minAspectRatioValue2,
      maxAspectRatio: _vm.maxAspectRatioValue1 / _vm.maxAspectRatioValue2,
    },"canvas":{
      minHeight: _vm.targetHeight,
      minWidth: _vm.targetWidth,
      maxHeight: _vm.targetHeight,
      maxWidth: _vm.targetWidth,
    },"stencil-size":{
      width: _vm.targetWidth,
      height: _vm.targetHeight,
    },"image-restriction":"stencil"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }