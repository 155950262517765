<template>
  <div>
    <cropper
      ref="cropper"
      class="cropper"
      :src="imageParam[0]"
      :auto-zoom="false"
      :stencil-props="{
        handlers: {},
        movable: false,
        scalable: false,
        resizable: false,
        minAspectRatio: minAspectRatioValue1 / minAspectRatioValue2,
        maxAspectRatio: maxAspectRatioValue1 / maxAspectRatioValue2,
      }"
      :canvas="{
        minHeight: targetHeight,
        minWidth: targetWidth,
        maxHeight: targetHeight,
        maxWidth: targetWidth,
      }"
      :stencil-size="{
        width: targetWidth,
        height: targetHeight,
      }"
      image-restriction="stencil"
    />
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: { Cropper },
  props: {
    imageParam: {
      type: Array,
      default: () => {},
    },
    targetWidth: {
      type: Number,
      default: () => 1024,
    },
    targetHeight: {
      type: Number,
      default: () => 768,
    },
    minAspectRatioValue1: {
      type: Number,
      default: () => 400,
    },
    minAspectRatioValue2: {
      type: Number,
      default: () => 400,
    },
    maxAspectRatioValue1: {
      type: Number,
      default: () => 400,
    },
    maxAspectRatioValue2: {
      type: Number,
      default: () => 200,
    },
  },

  data() {
    return {
      img: null,
      coordinates: {
        width: 1024,
        height: 768,
        left: 0,
        top: 0,
      },
      image: null,
    };
  },
  methods: {
  },
};
</script>

<style>
.cropper {
  height: 400px;
  width: 400px;
  background: #ddd;
}
</style>
