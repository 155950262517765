const convertImageToFile = (base64, filename) => {
  const arr = base64[0].split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const getMimeType = (base64) => {
  const [, type] = base64[0].split(';')[0].split('/');
  return type;
};

export default { getMimeType, convertImageToFile };
